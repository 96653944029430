
import { gql } from '@apollo/client';

export const ERROR_HANDLE = gql`mutation MyMutation
(
  $answerJson:String,
  $errorJson: String
) {
  addErrorTravelAnswer(input: {
    answerJson: $answerJson,
    errorJson: $errorJson
  }) {
    numUids
  }
}`;

export const SUBMIT_QUESTION = gql`mutation MyMutation
(
$key:String
$name: String!,
$phone: String!,
$email: String!,
$from: String!,
$destination: String!,
$startDate: String!,
$endDate: String!,
$adultsCount: Int!,
$budget: String!,
$vacationType: [String!]
$submitTime: DateTime,
$roomRequirement: [String],
$resortRquirements: [String],
$previouslyStayedResortInfo: String,
$kidsCount: Int,
$interests: [String],
$bookingItems:[String],
$importantInfo: String,
$comment: String,
$roomInfo:[RoomInfoRef]
$tripDateFelxibility: Int,
$summary: String,
$cities: String,
$attractions: String,
$tours: String,
$shipPreference: String,
$desiredCruiseLength: String,
$desiredCuriseMonth: String,
$cruiseLengthComment: String,
$stayRequirements:[String],
$amenities: [String]
$cruiseDestinations: [String],
$questionType:TravelQuestionType,
$preferredCruiseLines: [String]) {
  addTravelAnswer(input: {name: $name, 
    key:$key,
    phone: $phone, 
    email: $email, 
    from: $from, 
    destination: $destination, 
    startDate: $startDate, 
    endDate: $endDate, 
    adultsCount: $adultsCount, 
    budget: $budget, 
    vacationType: $vacationType, 
    submitTime: $submitTime, 
    roomRequirement: $roomRequirement, 
    resortRquirements: $resortRquirements, 
    previouslyStayedResortInfo: $previouslyStayedResortInfo, 
    kidsCount: $kidsCount, 
    interests: $interests, 
    bookingItems: $bookingItems,
    importantInfo: $importantInfo, 
    comment: $comment, 
    cities:$cities,
    roomInfo: $roomInfo,
    tripDateFelxibility:$tripDateFelxibility,
    summary:$summary,
    attractions:$attractions,
    tours:$tours,
    stayRequirements:$stayRequirements,
    amenities: $amenities,
   cruiseDestinations:$cruiseDestinations,
   preferredCruiseLines: $preferredCruiseLines,
   shipPreference:$shipPreference,
   desiredCruiseLength:$desiredCruiseLength,
   desiredCuriseMonth: $desiredCuriseMonth,
   questionType:$questionType
   cruiseLengthComment: $cruiseLengthComment}) {
    numUids
  }
}`;


export const SEND_EMAIL = gql`mutation MyMutation
(
  $to: String!
  $subject: String!
  $body: String!
  $submitTime: DateTime
){
  addSendEmail(
    input:{
      to: $to
      subject: $subject
      body: $body
      submitTime: $submitTime
  }) {
    numUids
  }
}`;
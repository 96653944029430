import * as Actions from '../actions';

const initialState = {
    Questions: [],
    ParamLocation: '',
    Summary: {
        name: {
            key: "firstNameLastName",
            value: ''
        },
        phone: {
            key: "phone",
            value: ''
        },
        email: {
            key: "email",
            value: ''
        },
        bookingHelp: {
            key: "bookingHelp",
            value: ''
        },
        from: {
            key: "departureAirport",
            value: ''
        },
        destination: {
            key: "destination",
            value: ''
        },
        occasion: {
            key: "tripOccasion",
            value: ''
        },
        startEndDate: {
            key: "tripDateRange",
            value: {}
        },
        budget: {
            key: "tripBudget",
            value: ''
        },
        cities: {
            key: "cities",
            value: ''
        },
        passangers: {
            key: "tripPassengers",
            value: ''
        },
        interests: {
            key: "activities",
            value: []
        },
        comment: {
            key: "comment",
            value: ''
        },
        importanInfo: {
            key: "132345",
            value: ''
        },
        stayRequirements: {
            key: "stay",
            value: ''
        },
        accomodationPreferences: {
            key: "accomodationPreferences",
            value: ''
        },
        attractions: {
            key: "attractions",
            value: ''
        },
        tours: {
            key: "tours",
            value: ''
        },
        roomPreference: {
            key: "roomPreferences",
            value: []
        },
        previousHotels: {
            key: "previousHotels",
            value: ''
        },
        amenities: {
            key: "132345",
            value: ''
        },
        cruiseDestinations: {
            key: "cruiseDestinations",
            value: []

        },
        preferredCruiseLines: {
            key: "preferredCruiseLines",
            value: []
        },
        shipPreference: {
            key: "shipPreference",
            value: ''
        },
        desiredCruiseLength: {
            key: "desiredCruiseLength",
            value: ''
        },
        desiredCuriseMonth: {
            key: "desiredCuriseMonth",
            value: ''
        },
        cruiseLengthComment: {
            key: "cruiseLengthComment",
            value: ''
        }
    }
}
const questionReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case Actions.SET_QUESTIONS:
            return {
                ...state,
                Questions: action.Questions
            }
        case Actions.UPDATE_SUMMARY:
            return {
                ...state,
                Summary: action.Summary
            }
        case Actions.LOCATION:
            return {
                ...state,
                ParamLocation: action.ParamLocation
            }
        default: // need this for default case
            return state
    }
}
export default questionReducer;